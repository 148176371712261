body {
  background: url(../src/assets/img/background.jpeg) center center no-repeat;
  background-color: #FEFEFE;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  user-select: none;
}

.logo {
  height: 258px;
  width: 366px;
  margin-bottom: 20%;
  margin-top: 20%;
  -webkit-transition: all 2s;
  -moz-transition: all 2s;
  transition: all 2s;
  animation: zoom 2s linear infinite;
  animation-fill-mode: forwards;
}
@keyframes zoom {
  0%{transform: scale(1.0);}
  10%{transform: scale(1.01);}
  20%{transform: scale(1.05);}
  30%{transform: scale(1.10);}
  40%{transform: scale(1.05);}
  50%{transform: scale(1.01);}
  60%{transform: scale(1.0);}
}

.text-building {
  color: #ff85a1;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  justify-content: center;
  display: flex;
}

.dev-logo {
  display: flex;
  justify-content: center;
}

.dev-logo img {
  height: 90px;
  width: 90px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.footer img {
  height: 20px;
  width: px;
}

.footer h3 {
  color: #48687d;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 15px;

}


  
  